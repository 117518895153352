<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <item-edit-view
      title="Процедура"
      :subtitle="item ? item.name : null"
      :loading="loading"
    >
      <template v-slot:right v-if="item && !loading">
        <v-btn @click="copy" icon title="Скопировать">
          <v-icon small>fa-copy</v-icon>
        </v-btn>

        <v-btn
          :href="previewUrl"
          target="_blank"
          title="Превью"
          icon
          class="mr-4"
        >
          <v-icon>mdi-eye</v-icon>
        </v-btn>
        <v-btn
          right
          color="success"
          :loading="saving"
          @click.prevent="handleSubmit(save)"
        >
          <v-icon small>mdi-content-save</v-icon>
          Сохранить
        </v-btn>
      </template>

      <v-card>
        <v-form v-if="!loading && item">
          <v-tabs v-model="tab" background-color="primary" dark>
            <v-tab key="info">Инфо</v-tab>
            <v-tab key="photo">Фото</v-tab>
            <v-tab key="faq">FAQ</v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item key="info">
              <v-card flat>
                <v-card-text>
                  <v-select
                    v-model="item.status"
                    :items="statuses"
                    label="Статус"
                  >
                  </v-select>

                  <validation-provider
                    v-slot="{ errors }"
                    name="Название"
                    rules="required"
                  >
                    <v-text-field
                      v-model="item.name"
                      label="Название"
                      :error-messages="errors"
                      required
                    ></v-text-field>
                  </validation-provider>

                  <validation-provider
                    v-slot="{ errors }"
                    name="Uri"
                    rules="required"
                  >
                    <slug-editor
                      name="uri"
                      class="mb-12 mt-4"
                      :input="item.name"
                      label="URL-адрес статьи"
                      placeholder="URL-адрес статьи"
                      v-model="item.slug"
                      :error="errors[0]"
                      persistent-hint
                      hide-details="auto"
                      :path="publicUrl"
                    />
                  </validation-provider>

                  <div>
                    <div class="body-1">Описание процедуры</div>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Описание"
                      rules="required"
                    >
                      <v-input
                        :value="item.description"
                        :error-messages="errors"
                        required
                      >
                        <quill
                          v-model="item.description"
                          :options="quillToolbar.description"
                        >
                        </quill>
                      </v-input>
                    </validation-provider>
                  </div>

                  <div>
                    <div class="body-1">Как подготовиться</div>
                    <v-input :value="item.howToPrepare">
                      <quill
                        v-model="item.howToPrepare"
                        :options="quillToolbar.howToPrepare"
                      />
                    </v-input>
                  </div>

                  <div class="mb-4">
                    <label for="relatedProcedures"
                      >Связанные процедуры и манипуляции</label
                    >
                    <multiselect
                      v-model="item.relatedProcedures"
                      id="relatedProcedures"
                      @search-change="findProcedures"
                      :loading="procedures.isLoading"
                      :internal-search="false"
                      :options="procedures.items"
                      :allow-empty="true"
                      :show-labels="true"
                      :searchable="true"
                      :taggable="true"
                      :multiple="true"
                      placeholder="Процедуры и манипуляции"
                      track-by="id"
                      label="name"
                    >
                    </multiselect>
                  </div>

                  <div class="mb-4">
                    <label for="diseases">Заболевания</label>
                    <multiselect
                      v-model="item.diseases"
                      id="diseases"
                      @search-change="findDiseases"
                      :loading="diseases.isLoading"
                      :internal-search="false"
                      :options="diseases.items"
                      :allow-empty="true"
                      :show-labels="true"
                      :searchable="true"
                      :taggable="true"
                      :multiple="true"
                      placeholder="Заболевания"
                      track-by="id"
                      label="name"
                    >
                    </multiselect>
                  </div>

                  <div class="mt-10 mb-10">
                    <div class="text-h5 mb-2">Услуги</div>
                    <v-text-field
                      dense
                      outlined
                      v-model="item.serviceBlockName"
                      label="Название блока"
                    ></v-text-field>
                    <div>
                      <label for="services">Привязанные услуги</label>
                      <multiselect
                        v-model="item.services"
                        id="services"
                        @search-change="findServices"
                        :loading="services.isLoading"
                        :internal-search="false"
                        :options="services.items"
                        :allow-empty="true"
                        :show-labels="true"
                        :searchable="true"
                        :taggable="true"
                        :multiple="true"
                        placeholder="Привязанные услуги"
                        track-by="id"
                        label="name"
                      >
                      </multiselect>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item key="photo">
              <v-card flat>
                <v-card-text>
                  <v-btn @click="choosePhoto" class="mr-1">{{
                    item.photoId ? "Изменить" : "Выбрать"
                  }}</v-btn>
                  <v-btn color="error" v-if="item.photo" @click="removePhoto"
                    >Удалить</v-btn
                  >
                  <v-img
                    v-if="item.photo"
                    :src="item.photo.url"
                    max-width="1024"
                    contain
                    class="mt-2"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item key="faq">
              <v-card>
                <v-card-text>
                  <Faq
                    :faqItems="item.faq"
                    :faqTitle="item.faqBlockName"
                    @remove-faq="removeFaq"
                    @change-title="updateFaqTitle"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-form>
      </v-card>
    </item-edit-view>
  </ValidationObserver>
</template>

<script>
import createOrUpdateViewMixin from "../../mixins/createOrUpdateView";
import proceduresService from "../../services/procedures";
import diseasesService from "../../services/diseases";
import servicesService from "../../services/service";
import {
  QUILL_EXTENDED,
  QUILL_BASIC
} from "../../components/elements/quilljs/configs";
import debounce from "lodash/debounce";
import { STATUS_LABELS } from "./articleStatus";
import SlugEditor from "../../components/Slug/SlugEditor.vue";
import WhatDoWeTreatItem from "./WhatDoWeTreatItem.vue";
import Faq from "./Faq.vue";

export default {
  extends: WhatDoWeTreatItem,
  components: {
    SlugEditor,
    Faq
  },
  mixins: [createOrUpdateViewMixin(proceduresService)],
  data() {
    return {
      tab: null,
      quillToolbar: {
        description: QUILL_EXTENDED,
        howToPrepare: QUILL_BASIC
      },
      statuses: STATUS_LABELS,
      photo: null,
      services: {
        items: [],
        isLoading: false
      },
      procedures: {
        items: [],
        isLoading: false
      },
      diseases: {
        items: [],
        isLoading: false
      },
      uuid: this.$route.params.id
    };
  },
  computed: {
    api() {
      return proceduresService;
    },
    id() {
      return this.item.id;
    },
    slug() {
      return this.item.slug;
    }
  },
  methods: {
    choosePhoto() {
      this.openPhotoModal().$once("choose", image => {
        this.item.photo = image;
      });
    },
    removePhoto() {
      this.item.photo = null;
    },
    removeFaq(value) {
      this.item.faq = value;
    },
    updateFaqTitle(value) {
      this.item.faqBlockName = value;
    },
    findServices: debounce(async function(query) {
      if (this.services.isLoading) {
        return;
      }
      this.services.isLoading = true;
      try {
        const r = await servicesService.getAll({
          isPublic: false,
          search: query
        });
        this.services.items = r.items;
        this.services.isLoading = false;
      } catch (e) {
        console.error(e);
        this.services.isLoading = false;
      }
    }, 1000),
    findProcedures: debounce(async function(query) {
      if (this.procedures.isLoading) {
        return;
      }
      this.procedures.isLoading = true;
      try {
        const r = await proceduresService.getAll({ search: query });
        this.procedures.items = r.items;
        this.procedures.isLoading = false;
      } catch (e) {
        console.error(e);
        this.procedures.isLoading = false;
      }
    }, 1000),
    findDiseases: debounce(async function(query) {
      if (this.diseases.isLoading) {
        return;
      }
      this.diseases.isLoading = true;
      try {
        const r = await diseasesService.getAll({ search: query });
        this.diseases.items = r.items;
        this.diseases.isLoading = false;
      } catch (e) {
        console.error(e);
        this.diseases.isLoading = false;
      }
    }, 1000),
    async copy() {
      await proceduresService.copy(this.uuid);
    }
  }
};
</script>
